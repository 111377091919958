/* eslint-disable no-underscore-dangle */
import { getLocalTimeZone } from '@internationalized/date';

import type {
  ComputeFreightEmissions,
  EmissionFreightCargoTypeEnum,
  FreightRouteStops,
  LocationCode,
  LocationFreight,
  MovementWithDistance,
  MovementWithLocation,
} from '../../../services/graphql/generated';
import type { CalculatorInputLegV2, CalculatorLocation, CalculatorParentState } from '../types';
import { CalculatorLocationTypeEnum } from '../types';

type PublicApiMovement = ComputeFreightEmissions['movements'][number];

function serializeConfig(leg: CalculatorInputLegV2): PublicApiMovement['config'] {
  if (
    (!leg.detail?.sea_restricted_areas || leg.detail.sea_restricted_areas.length === 0) &&
    !leg.detail?.ferry_preference
  ) {
    return undefined;
  }

  const { sea_restricted_areas: seaRestrictedAreas, ferry_preference: ferryPreference } = leg.detail;

  return {
    __typename: 'Configuration',
    routing: {
      __typename: 'RoutingConfiguration',
      sea:
        seaRestrictedAreas && seaRestrictedAreas?.length > 0
          ? {
              __typename: 'SeaRoutingConfiguration',
              restricted_areas: seaRestrictedAreas,
            }
          : undefined,
      road: ferryPreference
        ? {
            __typename: 'RoadRoutingConfiguration',
            ferry_routing_preference: ferryPreference,
          }
        : undefined,
    },
  };
}

function serializeVehicle(leg: CalculatorInputLegV2): PublicApiMovement['vehicle'] {
  if (!leg.detail?.vehicle_used) throw new Error('Vehicle is required');

  return {
    __typename: 'FreightVehicleDetails',
    code: leg.detail?.vehicle_used,
    fuel_type: leg.detail?.fuel_type,
    carrier_code: leg.detail?.carrier?.code,
    carrier_name: leg.detail?.carrier?.name,
    voyage_no: undefined,
    vessel_name: leg.detail?.vessel?.name,
    aircraft_code: leg.detail?.aircraft_model?.iata_code ?? undefined,
    flight_no: leg.detail?.flight_no?.trim(),
    vessel_id: leg.detail?.vessel?.imo,
    is_refrigerated: leg.detail?.refrigerated,
    empty_running: Number.isFinite(leg.detail?.empty_running) ? leg.detail?.empty_running : undefined,
    emission_standard: leg.detail?.emission_standard ?? undefined,
    load_factor: Number.isFinite(leg.detail?.load_factor) ? leg.detail?.load_factor : undefined,
    load_type: leg.detail?.load_type ?? undefined,
    fuel_consumption:
      leg.detail?.fuel_consumption != null && Number.isFinite(leg.detail.fuel_consumption)
        ? {
            __typename: 'FreightFuelConsumption',
            value: leg.detail.fuel_consumption,
            unit: leg.detail?.fuel_consumption_unit ?? undefined,
          }
        : undefined,
  };
}

function serializeLocation(location: CalculatorLocation): LocationFreight {
  if (location.type === CalculatorLocationTypeEnum.PORT && location.unlocode) {
    return {
      __typename: 'LocationCode',
      code: location.unlocode,
    };
  }

  if (location.type === CalculatorLocationTypeEnum.AIRPORT && location.iata_code) {
    return {
      __typename: 'LocationCode',
      code: location.iata_code,
    };
  }

  if (location.coordinates) {
    return {
      __typename: 'LocationCoordinates',
      latitude: location.coordinates[0],
      longitude: location.coordinates[1],
      address: location.display_name,
    };
  }

  if (location.display_name) {
    return {
      __typename: 'LocationAddress',
      address: location?.display_name,
      country_code: location.country_code_alpha_2,
    };
  }

  throw new Error(`Error serializing location "${location.display_name}" of type "${location.type}"`);
}

function serializeRoute(stops: CalculatorLocation[] | undefined): FreightRouteStops | undefined {
  if (!stops || stops.length <= 0) return undefined;

  return {
    __typename: 'FreightRouteStops',
    stops: stops
      .filter((stop) => stop.iata_code || stop.unlocode)
      .map((stop) => serializeLocation(stop))
      .filter((stop): stop is LocationCode => stop.__typename === 'LocationCode'),
  };
}

function serializeLegWithLocations(
  leg: CalculatorInputLegV2,
  { cargoType }: { cargoType?: EmissionFreightCargoTypeEnum },
): MovementWithLocation {
  if (leg.from == null || leg.to == null) throw new Error(`Missing location for leg`);

  return {
    __typename: 'MovementWithLocation',
    cargo_type: cargoType,
    vehicle: serializeVehicle(leg),
    config: serializeConfig(leg),
    from: serializeLocation(leg.from),
    to: serializeLocation(leg.to),
    route: serializeRoute(leg.stops),
  };
}

function serializeLegWithDistance(
  leg: CalculatorInputLegV2,
  { cargoType }: { cargoType?: EmissionFreightCargoTypeEnum },
): MovementWithDistance {
  if (leg.distance == null) throw new Error(`Missing distance for leg`);

  return {
    __typename: 'MovementWithDistance' as const,
    distance: {
      __typename: 'FreightDistance',
      value: leg.distance,
      unit: leg.distance_unit,
    },
    cargo_type: cargoType,
    vehicle: serializeVehicle(leg),
    config: serializeConfig(leg),
    from: leg.starting_country_code
      ? {
          __typename: 'LocationCountry',
          country_code: leg.starting_country_code,
        }
      : undefined,
    to: undefined,
  };
}

export function mapCalculatorStateToPublicAPIRequest(
  state: CalculatorParentState,
  {
    track,
    requiresClientKey,
    requiresLspKey,
  }: {
    track?: boolean;
    requiresClientKey?: boolean;
    requiresLspKey?: boolean;
  } = {},
): ComputeFreightEmissions {
  if (!state.cargoDetail.weight) throw new Error('Weight is required');
  if (state.legs.length === 0) throw new Error('At least one leg is required');

  const {
    weight,
    weight_unit: weightUnit,
    cargo_type: cargoType,
    date,
    metadata,
    auto_transshipment: autoTranshipment,
  } = state.cargoDetail;
  const { distance, from } = state.legs[0];

  const updatedMetadata: Record<string, string> | undefined =
    metadata || requiresClientKey || requiresLspKey
      ? {
          ...(requiresClientKey ? { client_key: 'INSERT_CLIENT_KEY' } : undefined),
          ...(requiresLspKey ? { lsp_key: 'INSERT_LSP_KEY' } : undefined),
          ...metadata,
        }
      : undefined;

  const computeRequest: Omit<ComputeFreightEmissions, '__typename' | 'movements'> = {
    track,
    date: date ? date?.toDate(getLocalTimeZone()).toISOString() : undefined,
    metadata: updatedMetadata,
    config:
      autoTranshipment != null
        ? { __typename: 'FreightShipmentCalculationConfig', auto_transshipment: autoTranshipment }
        : undefined,
    weight: {
      __typename: 'Weight',
      value: weight,
      unit: weightUnit,
    },
  };

  if (distance != null) {
    return {
      __typename: 'ComputeFreightEmissionsWithDistanceAndWeightAndVehicle',
      movements: state.legs.filter((leg) => leg.isSaved).map((leg) => serializeLegWithDistance(leg, { cargoType })),
      ...computeRequest,
    };
  }

  if (from != null) {
    return {
      __typename: 'ComputeFreightEmissionsWithLocationsAndWeightAndVehicle',
      movements: state.legs.filter((leg) => leg.isSaved).map((leg) => serializeLegWithLocations(leg, { cargoType })),
      ...computeRequest,
    };
  }

  throw new Error('Distance or location is required');
}
